<template>
  <!-- <InitLayout> -->
  <!-- logo placeholder -->
  <div class="card-pf">
  <b-container>
    <b-row class="justify-content-center" no-gutters>
      <img
        src="@/assets/logo/giveProudly-log.svg"
        class="px-0 w-auto img-sized"
        alt="Give Proudly"
      />
    </b-row>
    <b-row class="justify-content-center">
      <span class="errorCode">404</span>
    </b-row>
    <b-row class="justify-content-center">
      <span class="error">Page not found</span>
    </b-row>
    <b-row class="justify-content-center pt-3">
      <span class="errorDis"
        >Whoops...We can't seem to find the page you are looking for.</span
      >
    </b-row>
  </b-container>
  </div>
  <!-- </InitLayout> -->
</template>

<script>
// services

// components
// @ is an alias to /src
// import InitLayout from "@/layout/InitLayout";

export default {
  name: "Error404",
  components: {
    // InitLayout,
  },
  data() {
    return {
      isAPILoading: false,
    };
  },
  watch: {},
  methods: {},
  mounted() {
    document.getElementById("backgroundImgId").style.background =
      "url(" +
      "https://s3.us-west-2.amazonaws.com/images.unsplash.com/application-1678203234294-dbad28f5c6d3image";
    (")");
    document.getElementById("backgroundImgId").style.backgroundSize = "cover";
    document.getElementById("backgroundImgId").style.overflowY = "hidden";
  },
};
</script>

<style scoped>
.card-pf {
    width: 80%;
    min-width: 300px;
    min-height: 420px;
    max-width: 500px;
    border-radius: 14px;
    margin: 0 auto; 
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    position: absolute; 
    top: 50%; 
    transform: translateY(-50%); 
    left: 0;
    right: 0;
    margin-right: 160px;
  }
.img-sized {
  height: 150px;
  width: 150px;
}

/* .position {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, 50%);
} */
.errorCode {
  width: 193px;
  height: 110px;
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 112px;
  text-align: center;
  color: #87cc74;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.error {
  width: 374px;
  height: 47px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #87cc74;
  flex: none;
  order: 2;
  flex-grow: 0;
}
.errorDis {
  width: 367px;
  height: 68px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #9fa2b4;
  flex: none;
  order: 3;
  flex-grow: 0;
}
.logo-img {
  width: 150px;
  height: 53.96px;
}
.align {
  margin-left: 12%;
  padding-top: 3%;
}
@media (min-width: 992px) {
  .position {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-40%, 50%);
  }
}
/* Extra small devices (portrait phones, less than 576px) */
/* @media (max-width: 575.98px) {
  .align {
    margin-left: 0%;
    padding-top: 10%;
  }
  .errorCode {
    font-size: 35px;
    margin-top: -35px;
  }
  .errorDis {
    font-size: 12px;
  }
  .error {
    font-size: 20px;
    margin-top: -30px;
  }
  .logo-img {
    width: 90px;
    height: 53.96px;
  }
} */
</style>
